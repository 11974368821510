<template>
  <div class="rule-list">
    <div class="left">
      <template :key="item" v-for="(item, index) in ruleList">
        <div @click="handleRuleItemClick(item, index)" :class="{ active: currentIndex == index }" class="rule-item">
          <div class="info">
            <div class="name">{{ item.ruleName }}</div>
            <div class="create-time">创建于: {{ item.createTime }}</div>
          </div>
          <div class="icon">
            <el-icon>
              <arrow-right />
            </el-icon>
          </div>
        </div>
      </template>
    </div>

    <div class="right">
      <div class="top-form">
        <div class="title">规则预览</div>
        <div class="form">
          <el-button @click="handleEditRuleClick" type="primary">修改规则</el-button>
          <el-button @click="handleRemoveRuleClick" type="danger">删除规则</el-button>
        </div>
      </div>
      <div class="rule-detail">
        <rule-form :ruleInfo='currentRuleForm'></rule-form>
        <div class="mask"></div>
      </div>
    </div>

  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch } from 'vue'
import RuleForm from '../../../../base-ui/rule-form/rule-form'
import { useRouter } from 'vue-router'
import store from '@/store'
import { COMPANY_ID } from '@/common/constant'
import cache from '@/utils/cache'
import emitter from '@/utils/eventbus'
export default defineComponent({
  props: {
    ruleList: {
      type: Array,
      default() {
        return []
      }
    }
  },
  components: {
    RuleForm
  },
  setup(props, { emit }) {
    const router = useRouter()
    const handleEditRuleClick = () => {
      cache.setCache('ruleInfo', currentRuleForm.value)
      router.push({
        path: '/main/reserve/editRule',
        query: {
          id: currentRuleForm.value.id
        }
      })
    }

    const currentRuleForm = ref(props.ruleList[0])
    const handleRuleItemClick = (item, index) => {
      currentIndex.value = index
      // console.log(item);
      currentRuleForm.value = item
    }

    const handleRemoveRuleClick = () => {
      store.dispatch('reserve/removeReserveRuleAction', [COMPANY_ID, currentRuleForm.value.id])
    }

    watch(() => props.ruleList, (n) => {
      currentRuleForm.value = n[0]
    })

    const currentIndex = ref(0)
    emitter.on('changeReserveRuleListCurrentindex', () => {
      currentIndex.value = 0
    })

    return {
      currentRuleForm,
      handleRuleItemClick,
      handleEditRuleClick,
      currentIndex,
      handleRemoveRuleClick,
      COMPANY_ID
    }

  }
})
</script>

<style scoped lang="less">
@media screen and (max-width: 1360px) {
  .rule-list {
    .left {
      width: 260px !important;
    }
  }
}

.rule-list {
  display: flex;
  margin-top: 20px;

  .left {
    width: 350px;

    .active {
      border: 1px solid rgba(255, 141, 26, 1) !important;

      .info {
        .name {
          color: rgba(255, 141, 26, 1);

        }
      }

      .icon {
        color: rgba(255, 141, 26, 1);
      }
    }

    .rule-item {
      display: flex;
      align-items: center;
      height: 74px;
      background-color: rgba(255, 255, 255, 1);
      border: 1px solid rgba(166, 166, 166, 0.6);
      border-radius: 5px;
      margin-bottom: 20px;
      padding: 0 20px;
      cursor: pointer;

      .info {
        flex: 1;

        .name {

          font-size: 14px;
          margin-bottom: 8px;
        }

        .create-time {
          color: rgba(166, 166, 166, 1);
          font-size: 12px;
        }
      }

      .icon {
        width: 24px;
      }
    }
  }

  .right {
    padding-left: 40px;
    flex: 1;

    .top-form {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(166, 166, 166, 1);

      .title {}

      .form {}
    }

    .rule-detail {
      margin-top: 20px;
      position: relative;

      .mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9;
      }
    }
  }

}
</style>




