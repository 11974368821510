<template>
  <div class="site-list">
    <page-content :dataList="reserveRuleList" class="page-content" :contentTableConfig="contentTableConfig"
      @tableCellClick="handleTableCellClick">

      <template #handler="scope">
        <div class="handle-btns">
          <el-button plain @click.stop="handleDetailClick(scope.row)" size="small">详情</el-button>
          <el-button type="danger" @click.stop="hanldeRemoveClick(scope.row)" size="mini" plain v-has="'set:delete'">删除</el-button>
          <el-button plain @click.stop="hanldeUpdateClick(scope.row)" size="small" v-has="'set:edit'">修改</el-button>
          <el-button plain @click.stop="handleFeiPeiClick(scope.row)" size="small">分配</el-button>
          <el-button v-if="scope.row.isdefault == 0" plain @click.stop="handleSetDefaultClick(scope.row)" size="small">
            设为默认</el-button>
        </div>
      </template>
    </page-content>
    <distribute v-if="dialogVisible" :ruleId="currentEditSiteId" @changeDialogVisible="changeDialogVisible" :dialogVisible="dialogVisible">
    </distribute>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed, onUnmounted } from 'vue'
import PageContent from '@/components/page-content2/src/page-content.vue'
import { contentTableConfig } from './config/index'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { useRouter } from 'vue-router'
import cache from '@/utils/cache'
import emitter from '@/utils/eventbus'
import { ElMessage } from 'element-plus'
import { setReserveSiteDefault, removeReserveRule } from '@/service/main/reserve'
import distribute from './cpns/distribute-dialog/distribute-dialog.vue'
export default defineComponent({
  props: {

  },
  components: {
    PageContent,
    distribute
  },
  setup() {
    const store = useStore()
    const router = useRouter()
    const reserveRuleList = computed(() => store.state.reserve.reserveRuleList)

    const initPage = () => {
      store.dispatch('reserve/getReserveRuleListAction', COMPANY_ID)
    }
    initPage()

    emitter.on('changeReserveRuleListPageContent', () => {
      initPage()
    })

    onUnmounted(() => {
      emitter.off('changeReserveRuleListPageContent')
    })

    const handleDetailClick = (item) => {
      cache.setCache('ruleInfo', item)
      router.push({
        path: "/main/reserve/reserveSiteDetail",
      })
    }

    const refresh = () => {
      ElMessage({
        message: '操作成功！',
        type: 'success',
      })
      initPage()
    }

    const handleSetDefaultClick = async item => {
      const res = await setReserveSiteDefault(item.id)
      if (res.code == 0) {
        refresh()
      }
    }

    const hanldeRemoveClick = async (item) => {
      const res = await removeReserveRule(COMPANY_ID, item.id)
      if (res.code == 0) {
        refresh()
      }
    }

    const hanldeUpdateClick = (item) => {
      cache.setCache('ruleInfo', item)
      router.push({
        path: '/main/reserve/editRule',
        query: {
          id: item.id
        }
      })
    }


    const dialogVisible = ref(false)
    const changeDialogVisible = flag => {
      dialogVisible.value = flag
    }

    const handleFeiPeiClick = (item) => {
      currentEditSiteId.value = item.id
      dialogVisible.value = true
    }

    const currentEditSiteId = ref('')


    return {
      currentEditSiteId,
      handleFeiPeiClick,
      dialogVisible,
      changeDialogVisible,
      hanldeUpdateClick,
      hanldeRemoveClick,
      handleSetDefaultClick,
      handleDetailClick,
      contentTableConfig,
      reserveRuleList
    }

  }
})
</script>

<style scoped lang="less">
.site-list {
  .handle-btns {
    display: flex;
    flex-wrap: wrap;

    &:deep(.el-button) {
      margin-left: 0 !important;
      margin: 4px !important;
    }
  }
}
</style>




