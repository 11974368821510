<template>
  <div class="prepaid-site">
    <el-form ref="ruleFormRef" label-position="left" :model="ruleForm" :rules="rules" label-width="180px"
      class="demo-ruleForm">
      <el-form-item label="线上预约是否需要预付:" prop="reserveSite">
        <el-radio-group v-model="ruleForm.reserveSite">
          <el-radio :label="1">无需支付</el-radio>
          <el-radio :label="2">需支付</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item label="卡项是否使用余额支付:" prop="reserveSite">
        <el-radio-group v-model="ruleForm.cardPaySite">
          <el-radio :label="1">使用</el-radio>
          <el-radio :label="0">禁用</el-radio>
        </el-radio-group>
      </el-form-item>

      <el-form-item label="">
        <el-button  @click="handleSaveClick(ruleFormRef)" type="primary">保存</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import { globalSetResercvePrice,getServePreStatus } from '@/service/main/reserve'
import { ElMessage } from 'element-plus'
import { COMPANY_ID } from '@/common/constant'
import { isBuyCard, updateIsBuyCard } from '@/service/main/base'

export default defineComponent({
  props: {

  },
  components: {

  },
  setup() {
    const rules = {
      reserveSite: [
        { required: true, message: 'Please input Activity name', trigger: 'blur' },
      ]
    }
    const ruleFormRef = ref(null)
    const ruleForm = reactive({
      reserveSite: 2,
      cardPaySite: ''
    })

    const handleSaveClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          const res = await globalSetResercvePrice(ruleForm.reserveSite, COMPANY_ID)
          await updateIsBuyCard(ruleForm.cardPaySite)
          // await
          if (res.code == 0) {
            ElMessage({
              message: '操作成功!',
              type: 'success',
            })
          }
          // console.log('submit!')
        } else {
          // console.log('error submit!', fields)
        }
      })

    }

    const initPage = async () => {
      const res = await isBuyCard()
      // console.log(res);
      ruleForm.cardPaySite = res.data
      const d = await getServePreStatus()
      ruleForm.reserveSite = d.data
    }
    initPage()

    return {
      handleSaveClick,
      ruleFormRef,
      ruleForm,
      rules
    }

  }
})
</script>

<style scoped lang="less">
.prepaid-site {}
</style>
