<template>
  <div class="add-site">
    <breadcrumb :breadcrumbList="addcheckBreadcrumb"></breadcrumb>
    <div class="add-site-content">
      <el-form ref="ruleFormRef" :model="ruleForm" :rules="reserveRule" label-width="120px" class="ruleForm" :size="formSize">
        <el-form-item label="规格名称：" prop="ruleName">
          <el-input v-model="ruleForm.ruleName" />
        </el-form-item>

        <!-- <el-form-item label="选择门店：" prop="shopId">
          <div class="shop">
            <el-select @change="handleShopChange" v-model="ruleForm.shopId" class="m-2" placeholder="选择门店" size="large">
              <el-option v-for="item in shopList" :key="item.shopId" :label="item.shopName" :value="item.shopId">
              </el-option>
            </el-select>
          </div>
        </el-form-item> -->

        <!-- <el-form-item label="预约时间：" prop="name">
          <div class="time">
            <el-input placeholder="" v-model="ruleForm.appWeekDays" />
            <el-time-select v-model="ruleForm.appStartTime" :max-time="ruleForm.appEndTime" class="mr-4"
              placeholder="开始时间" start="08:00" step="00:15" end="22:30"></el-time-select>
            <span>到</span>
            <el-time-select v-model="ruleForm.appEndTime" :min-time="ruleForm.appStartTime" placeholder="结束时间"
              start="08:30" step="00:15" end="22:30"></el-time-select>
          </div>
        </el-form-item> -->

        <el-form-item label="预约范围：" prop="startMinutes">
          <div class="scope">
            <el-form-item prop="startMinutes">
              <el-input v-model="ruleForm.startMinutes" />
            </el-form-item>
            分钟
            <span>到</span>
            <el-form-item prop="endMinutes">
              <el-input v-model="ruleForm.endMinutes" />
            </el-form-item>
            <span>天内的到店服务</span>
          </div>
        </el-form-item>

        <el-form-item label="项目占用时长：" prop="name">
          <div class="duration">
            <el-form-item>
              <el-radio v-model="ruleForm.serveOccupyTimeRadio" label="0" size="large">按照项目时长计算占用</el-radio>
              <el-radio v-model="ruleForm.serveOccupyTimeRadio" label="1" size="large">
                <span>固定为</span>
                <el-input :disabled="ruleForm.serveOccupyTimeRadio == '0'" v-model="ruleForm.serveOccupyFlag" />
                <span>分钟</span>
              </el-radio>
            </el-form-item>
          </div>
        </el-form-item>

        <el-form-item label="取消预约：">
          <div class="cancel-reserve">
            <el-radio v-model="ruleForm.cancelTimeLimitRadio" label="0" size="large">任意时间</el-radio>
            <el-radio v-model="ruleForm.cancelTimeLimitRadio" label="1" size="large">
              <span>预约到店时间前</span>
              <el-input :disabled="ruleForm.cancelTimeLimitRadio == '0'" v-model="ruleForm.cancelTimeLimit" />
              <span>分钟，客户需联系客服取消或修改预约单</span>
            </el-radio>

          </div>
        </el-form-item>

        <el-form-item label="预约超时取消：">
          <div class="time-out">
            <el-radio v-model="ruleForm.autoCancelRadio" label="0" size="large">否</el-radio>
            <el-radio v-model="ruleForm.autoCancelRadio" label="1" size="large">
              <span>超过客户预约到店时间</span>
              <el-input :disabled="ruleForm.autoCancelRadio == '0'" v-model="ruleForm.autoCancel" />
              <span>分钟未到店，自动取消预约</span>
            </el-radio>
          </div>
        </el-form-item>

        <!-- <el-form-item label="线上预约:" prop="servePayFlag">
          <div class="online-reserve">
            <el-radio v-model="ruleForm.servePayFlag" label="0" size="large">无需支付</el-radio>
            <el-radio v-model="ruleForm.servePayFlag" label="2" size="large">
              <span>需支付</span>
              <el-select v-model="ruleForm.servePayKind" class="m-2" placeholder="按百分比" size="large">
                <el-option v-for="item in payOptions" :key="item.value" :label="item.label" :value="item.value">
                </el-option>
              </el-select>
              <el-input v-model="ruleForm.reservePayAmount">
                <template #suffix>
                  <span v-if="ruleForm.servePayKind == '2'">元</span>
                  <span v-else>%</span>
                </template>
              </el-input>
            </el-radio>
          </div>
        </el-form-item> -->

        <el-form-item>
          <el-button @click="handleSaveClick(ruleFormRef)" class="save-btn" type="primary">保存</el-button>
        </el-form-item>
      </el-form>
    </div>

  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import { useStore } from 'vuex'
import emitter from '@/utils/eventbus'
import { COMPANY_ID } from '@/common/constant'
import { timeCategory, payOptions, addcheckBreadcrumb } from '../../config'
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import {reserveRule} from '../../config'
export default defineComponent({
  props: {

  },
  components: {
    breadcrumb
  },
  setup() {
    const store = useStore()

    const ruleForm = reactive({
      ruleName: '',
      companyId: COMPANY_ID,
      shopId: '0',
      appWeekDays: "",
      appStartTime: "",
      appEndTime: "",
      startMinutes: "",
      endMinutes: "",
      serveOccupyFlag: "",
      timeCategory: '',
      serveOccupyTimeRadio: '0',
      cancelTimeLimitRadio: '0',
      cancelTimeLimit: '',
      autoCancel: '',
      autoCancelRadio: '0'
    })

    const emptyForm = () => {
      for (let key in ruleForm) {
        if (key != 'companyId') {
          ruleForm[key] = ''
        }
      }
    }

    const shopList = computed(() => store.state.reserve.shopList)

    const handleSaveClick = async (formEl = undefined) => {
      if (!formEl) return
      await formEl.validate(async (valid, fields) => {
        if (valid) {
          store.dispatch('reserve/addReserveRuleAction', { ...ruleForm })
          emptyForm()
          // console.log('submit!')
        } else {
          // console.log('error submit!', fields)
        }
      })
    }

    const ruleFormRef = ref()

    const initPage = () => {
      store.dispatch('reserve/getShopListAction', COMPANY_ID)
    }
    initPage()

    return {
      ruleFormRef,
      ruleForm,
      shopList,
      timeCategory,
      handleSaveClick,
      payOptions,
      addcheckBreadcrumb,
      reserveRule
    }

  }
})
</script>

<style scoped lang="less">
.add-site {
  .add-site-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;

    .ruleForm {

      .online-reserve {
        &:deep(.el-input) {
          max-width: 120px !important;
          margin-left: 10px;
        }
      }

      &:deep(.el-form-item) {
        .el-input {
          max-width: 600px;
        }
      }

      .shop {
        max-width: 600px;

        &:deep(.el-select) {
          width: 100%;
        }
      }

      .save-btn {
        width: 150px;
        height: 48px;
        margin-top: 10px;
      }

      .pause-reserve {
        .top {
          &:deep(.el-select) {
            max-width: 120px;
            margin: 0 10px;
          }

          &>span:last-child {
            color: rgba(42, 130, 228, 1);
            font-size: 14px;
          }
        }

        .center {
          color: rgba(166, 166, 166, 1);
          font-size: 12px;
        }
      }

      .time-out {
        &:deep(.el-input) {
          max-width: 80px;
          min-width: 60px;
          margin: 0 10px;
        }
      }

      .duration,
      .cancel-reserve {
        &:deep(.el-select) {
          max-width: 120px;
          margin: 0 10px;
        }
      }

      .duration {
        max-width: 600px;

        &:deep(.el-input) {
          max-width: 150px;
          margin: 0 10px;
        }
      }

      .cancel-reserve {
        display: flex;
        justify-content: space-between;
        max-width: 600px;

        &:deep(.el-input) {
          max-width: 100px;
          margin: 0 10px;
        }
      }

      .tips {

        .top,
        .bottom {
          display: flex;

          &:deep(.el-input) {
            max-width: 80px;
            min-width: 60px;
            margin: 0 10px;
          }
        }

        .bottom {
          margin-top: 10px;
        }
      }

      .time {
        display: flex;
        justify-content: space-between;
        max-width: 600px;

        span {
          margin: 0 10px;
        }

        &:deep(.el-input) {
          margin-right: 10px;
          max-width: 240px;
        }

        &:deep(.el-select) {
          max-width: 120px;
          margin-left: 10px;
        }
      }

      .scope {
        display: flex;
        max-width: 600px;
        justify-content: space-between;

        &:deep(.el-input) {
          max-width: 80px;
          min-width: 60px;
          margin-right: 10px;
        }

        &:deep(.el-select) {
          .el-input {
            max-width: 120px;
          }
        }

        span {
          margin-right: 10px;
        }
      }
    }
  }

}
</style>







