<template>
  <div class="site">
    <breadcrumb v-if="activeTabs == 'add'" :breadcrumbList="addBreadcrumbList"></breadcrumb>
    <breadcrumb v-else :breadcrumbList="checkBreadcrumbList"></breadcrumb>
    <div class="site-content">
      <div class="top-content">
        <el-button @click="handleAddRule" type="primary" v-has="'set:add'" >新建预约规则</el-button>
      </div>
      <el-tabs v-model="activeTabs" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane name="add" label="预约规则">
          <siteList></siteList>
        </el-tab-pane>
        <el-tab-pane name="check" label="预付设置">
          <prepaidSite></prepaidSite>
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref } from 'vue'
import breadcrumb from '@/components/breadcrumb/breadcrumb.vue'
import { addBreadcrumbList, checkBreadcrumbList } from './config'
import AddSite from './cpns/add-site/add-site.vue'
import CheckSite from './cpns/check-site/check-site.vue'
import siteList from './cpns/site-list/site-list'
import prepaidSite from './cpns/prepaid-site/prepaid-site.vue'
import { useRouter } from 'vue-router'
export default defineComponent({
  props: {

  },
  components: {
    breadcrumb,
    AddSite,
    CheckSite,
    siteList,
    prepaidSite
  },
  setup() {
    const activeTabs = ref('add')
    const router=useRouter()
    const handleAddRule=()=>{
      router.push({
        path:'/main/reserve/addReserveSite'
      })
    }


    return {
      handleAddRule,
      addBreadcrumbList,
      checkBreadcrumbList,
      activeTabs
    }

  }
})
</script>

<style scoped lang="less">
.site {
  .site-content {
    padding: 20px;
    margin: 20px;
    background-color: #fff;
    min-width: 960px;
    position: relative;
    .top-content{
      position: absolute;
      right: 20px;
      z-index: 99;
      top: 30px;
    }
    &:deep(.el-tabs){
      margin-top: 20px;
    }
  }
}
</style>




