<template>
  <div class="check-site">
    <rule-list :ruleList="reserveRuleList"></rule-list>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, computed } from 'vue'
import RuleList from './cpns/rule-list/rule-list.vue'
import { useState } from '@/hooks'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import emitter from '@/utils/eventbus'
import { useRoute } from 'vue-router'
export default defineComponent({
  props: {

  },
  components: {
    RuleList
  },
  setup() {
    const store = useStore()
    const route=useRoute()
    const reserveRuleList = computed(() => store.state.reserve.reserveRuleList)

  
    const initPage = () => {
      store.dispatch('reserve/getReserveRuleListAction', COMPANY_ID)
    }
    initPage()


    return {
      reserveRuleList
    }

  }
})
</script>

<style scoped lang="less">
.check-site{
  min-width: 960px;
}
</style>




