<template>
  <div class="add-rank-dialog">
    <page-dialog :width="25" v-if="dialogVisible" @changeDialogVisible="changeDialogVisible"
      :dialogVisible="dialogVisible" title="关联门店">
      <div class="shop-list">
        <div class="titls">
          <div>门店名称</div>
          <div>状态</div>
        </div>
        <el-scrollbar height="200px">
          <el-checkbox-group @change="handleCheckChange" v-model="checkList">
            <template :key="item" v-for="item in optDataList">
              <el-checkbox :disabled="item.checked" @click="checkBoxClick(item)" @change="handleCheckBoxChange" :label="item.shopId">
                <div class="item">
                  <div class="left">
                    <div class="name">{{ item.shopName }}</div>
                  </div>
                  <div class="right">
                    <el-tag v-if="checkList.some(ele => ele == item.shopId)" size="small" class="ml-2" type="success">
                      已分配</el-tag>
                    <el-tag v-else size="small" class="ml-2" type="info"> 未分配</el-tag>
                  </div>
                </div>
              </el-checkbox>
            </template>
          </el-checkbox-group>
        </el-scrollbar>
      </div>
    </page-dialog>
  </div>
</template>

<script lang="js">
import { defineComponent, reactive, ref, watch, nextTick, computed, onUnmounted } from 'vue'
import PageDialog from '@/components/page-dialog/page-dialog.vue'
import { useStore } from 'vuex'
import { COMPANY_ID } from '@/common/constant'
import { rankCategory } from './config'
import emitter from '@/utils/eventbus'
import { ElMessage } from 'element-plus'
import { setReserveSiteBindShop, getShopList, getReserveSiteBindShopList,getBingOtherRule } from '@/service/main/reserve'

export default defineComponent({
  props: {
    dialogVisible: {
      type: Boolean,
      default: false
    },
    ruleId: {
      type: Number,
      default: -1
    },
    ruleKind: {
      type: Number,
      default: 1
    }
  },
  components: {
    PageDialog
  },
  emits: ['changeDialogVisible', 'refreshList'],
  setup(props, { emit }) {
    const store = useStore()
    const changeDialogVisible = (flag) => {
      emit('changeDialogVisible', flag)
    }


    const shopList = computed(() => store.state.serve.shopList)
    const shopCount = computed(() => store.state.serve.shopCount)

    const shopListInfo = ref([])
    watch(shopList, (n) => {
      // console.log(n);
      shopListInfo.value = n
    }, {
      deep: true
    })


    const checkList = ref([])
    const currentPage = reactive({
      page: 1,
      pagesize: 10,

    })

    const currentEditShop = reactive({
      id: 0,
      flag: false
    })

    const getBindList = async () => {
      const res = await getReserveSiteBindShopList(props.ruleId)
      let list = res.data.list
      list.forEach(item => {
        checkList.value.push(item.shopId)
      })
    }
    

    watch(currentPage, () => {
      initPage()
    }, {
      deep: true
    })

    const handleSizeChange = (number) => {
      currentPage.pagesize = number
    }

    const handleCurrentChange = (number) => {
      currentPage.page = number
    }

    const optDataList = ref([])

    const initPage = async () => {
      const res = await getShopList(COMPANY_ID)
      const checkedList = await getBingOtherRule(props.ruleId)
      //使用map循环
      res.data.list.map(item=>{
        item.checked = false
        checkedList.data.map(val=>{
          if(item.shopId == val){
            item.checked = true
          }
        })
      })
      optDataList.value = res.data.list
      getBindList()
    }
    initPage()

    const handleCheckBoxChange = (flag) => {
      let res
      nextTick(async () => {
        res = await setReserveSiteBindShop(props.ruleId, currentEditShop.id)
        if (res.code == 0) {
          ElMessage({
            message: '操作成功!',
            type: 'success',
          })
          emit('refreshList')
        } else {
          ElMessage.error(res.msg)
        }
        getBindList()

      })
    }

    const handleCheckChange = (value) => {
      // console.log(value);
    }

    const checkBoxClick = (item) => {
      // console.log(item);
      currentEditShop.id = item.shopId
    }






    watch(() => props.ruleId, (n) => {
      getBindList()
    }, {
      deep: true
    })


    const searchValue = ref('')


    return {
      optDataList,
      shopListInfo,
      searchValue,
      checkBoxClick,
      changeDialogVisible,
      rankCategory,
      shopList,
      shopCount,
      checkList,
      handleSizeChange,
      handleCurrentChange,
      currentPage,
      handleCheckBoxChange,
      handleCheckChange,
      currentEditShop,
    }

  }
})
</script>

<style scoped lang="less">
.add-rank-dialog {
  font-size: 12px;

  &:deep(.el-scrollbar__view) {
    font-size: 12px;
  }

  &:deep(.el-checkbox__label) {
    font-size: 12px;
  }

  .save {
    &:deep(.el-button) {
      width: 100%;
    }
  }

  &:deep(.is-horizontal) {
    display: none !important;
  }

  &:deep(.el-dialog) {
    min-width: 350px;

    .el-dialog__body {
      padding-bottom: 10px !important;
    }
  }

  &:deep(.el-dialog__body) {
    position: relative;
    top: -15px;
  }

  .search {
    margin-bottom: 14px;
  }

  &:deep(.el-select) {
    width: 100%;
  }

  &:deep(.el-scrollbar) {
    padding: 0 10px;
  }

  .pagination {
    margin-top: 10px;
    text-align: right;
  }

  .shop-list {
    .titls {
      padding: 0 10px;
      display: flex;
      margin-bottom: 20px;
      background-color: rgb(239, 239, 239);
      font-size: 12px;

      div {
        flex: 1;
        line-height: 40px;

      }
    }

    &:deep(.el-checkbox) {
      display: inline-block;
      width: 100% !important;

      .el-checkbox__label {
        display: inline-block;
        width: 100%;

        .item {
          width: 97%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .right {
            position: relative;
            left: -8px;
          }

          .left,
          .right,
          .center {
            flex: 1;
          }

          .left {
            display: flex;


          }
        }
      }
    }
  }
}
</style>




