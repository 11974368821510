export const contentTableConfig = {
  propList: [
    { prop: 'ruleName', label: '方案名称', minWidth: '220', slotName: 'ruleName' },
    { prop: 'createTime', label: '创建时间', minWidth: '200',slotName:'createTime' },
    {
      prop: 'useQty',
      label: '已关联门店',
      minWidth: '180',
      slotName: 'useQty'
    },
    { label: '操作', minWidth: '160', slotName: 'handler' }
  ],
  showIndexColumn: true,
  showSelectColumn: false
}
